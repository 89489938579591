@import 'lightbox.css';

html {
  height: 100%;
  margin: 0;
  font-family: sans-serif;
}

body {
  height: 100%;
  margin: 0;
}

#root {
  height: 100%;
}

html[data-joy-color-scheme="dark"] {
  background-color: var(--joy-palette-neutral-800, #23272B);
}

.nuka-page-container {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  bottom: 16px;
}

button .nuka-page-indicator {
  background-color: rgba(146, 148, 151, 0.9);
}

.sales-cabin-card .nuka-overflow {
  border-radius: 8px;
}

#hosted-fields {

  .braintree-hosted-fields-focused {
    border: 2px solid var(--joy-palette-primary-500);
    padding: 0px 0px 0px 9px;
  }


  form {
    display: flex;
    flex-wrap: wrap;
    gap: 5%;


    label,
    p {
      // default width
      // for flex
      width: 100%;
      font-family: var(--joy-fontFamily-body);
      font-size: var(--FormLabel-fontSize, var(--joy-fontSize-sm));
      font-weight: var(--joy-fontWeight-md);
      line-height: var(--FormLabel-lineHeight, var(--joy-lineHeight-sm));
      color: var(--FormLabel-color, var(--joy-palette-text-primary));
      margin: var(--FormLabel-margin, 0px);


      &.err {
        margin-top: 10px;
        color: red;
        font-style: italic;
      }

      &.sandbox {
        margin-top: 14px;
        background-color: red;
        color: yellow;
        text-align: center;
        padding: 6px;
      }
    }

    >div {
      // default width
      width: 100%;
      font-family: var(--joy-fontFamily-body);

      &:has(#cvv),
      &:has(#postal-code),
      &:has(#expiration-date) {
        width: 47.5%;

        @media (max-width: 768px) {
          width: 100%;
        }

      }

      >div {
        height: 2.25rem;
        background-color: white;
        overflow: hidden;

        border: 1px solid #E6EBEF;
        padding: 1px 1px 1px 10px;
        border-radius: 6px;


        margin-top: 0.5rem;
        margin-bottom: 0.6rem;
      }
    }

    button {
      width: 100%;
      margin-top: 10px;
    }


  }
}

#full-page-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F0F4F8;
}

.bounded-wave-loader {
  position: relative;
  overflow: hidden;
  border: 2px solid transparent;
  border-radius: 100px;
  width: 100px;
  height: 100px;
  box-shadow: 0 0 0 4px #000;
}

.bounded-wave-loader svg {
  position: absolute;
  bottom: 0;
  animation: wave-shift 2s linear infinite;
}

@keyframes shift {
  100% {
    transform: translateX(-50%);
  }
}

@keyframes wave-shift {
  100% {
    transform: translateX(-50%);
  }
}